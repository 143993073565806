.content-editor{

  display: flex;
  flex-wrap: wrap;

  .content-editor-item{
    padding: 15px;
    width: 400px;
  }

  .content-editor-item-name{
    font-weight: bold;
  }
}

.content-editor-news{
  margin: 30px;
  .content-editor-item{
    margin: 0 auto;
  }
}

.content-modal-editor{
  margin: 30px;

  .content-modal-controls{
    display: flex;
  }

  .content-modal-controls-item{
    margin: 5px;
  }

  .image-gallery-content{
    width: auto;
  }
}