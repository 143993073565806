.person-profile-select-container{
    background-color: gray;
    width: 500px;
    min-height: 100px;
    padding: 20px;
    position: relative;

}

.person-profile-select-item{
    width: 500px;

    .ant-select, .ant-input{
        width: 100%;
    }
    
}

.person-profile-select-options{
    position: absolute;
    
    right: 20px;
    top: 0;

    button {
        margin: 3px;
    }
}