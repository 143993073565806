@import "../../variables";


nav.side-nav-menu {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  //height: $nav-height;
  background-color: $nav-bg-color;
  color: $side-nav-text-color;
  display: block;
  align-items: center;
  z-index: 999;
  width: $side-nav-width;
  height: 100%;

  &.collapsed{
    width: auto;
  }



  ul {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    height: calc(100% - 40px);
    overflow: auto;
    font-size: 1.1em;


    li {
      padding: 5px;
      
      &:last-child {
        margin-right: 0;
      }

      a, span{
        color: $side-nav-text-color;
        text-decoration: none;
        font-size: 0.9em;
        transition: all 0.2s ease;

        &:hover {
          color: lighten($side-nav-text-color, 20%);
        }
      }
    }
  }
  &.collapsed ul{
    padding-right: 5px;
  }

  .menu-control-panel{
    position: absolute;
    right: 100px;
  }

  .side-nav-menu-preview{
    position: absolute;
    top: 10px;
    right: 5px;
    button {
      cursor: pointer;
      color: #113812;
      border: 3px solid #7acb6d;
      background: #dbfac6;
      border-radius: 5px;
    }
  }

  .side-menu{

  }

}

.side-nav-menu-header-content{
  background: #1d2731;
  height: 40px;

  .side-nav-menu-header-label{
    font-weight: bold;
    color: yellow;
  }

  .side-nav-menu-header-version{
    color: yellow;
  }

  .side-menu {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .side-menu li {
    position: relative;
  }

  .side-menu a, .side-menu span{
    display: block;
    padding: 10px;
    cursor: pointer;
  }

  .side-menu a:hover, .side-menu span:hover {
    color: lighten($side-nav-text-color, 15%);
    background-color: lighten($nav-bg-color, 8%);;
  }

  .side-submenu {
    position: absolute;
    top: 100%;
    left: 0;
    display: block;
    visibility: hidden;
    min-width: 200px;
    padding: 10px;
    background-color: $nav-bg-color;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    transition: visibility 0s 0.2s, opacity 0.2s linear;
    opacity: 0;

  }

  .side-menu li:hover > .side-submenu {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.2s linear;
  }

  @keyframes fade-in {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes fade-out {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(-10px);
    }
  }
}


