.reservation-dashboard-component-content{
  .reservation-dashboard-component-item{
    background-color: #dceffc;
    padding: 5px;
    margin: 5px;
    position: relative;

    &.canceled{
      background-color: #ffa7a7;
    }

    .cancel-overlay{
      position: absolute;
      top: 50px;
      left: 200px;
      color: rgba(255, 0, 0, 0.47);
      font-weight: bold;
      font-size: 2em;
      z-index: 10;
      rotate: -20deg;
    }
  }

  .reservation-dashboard-component-info{
    display: flex;
    flex-wrap: wrap;
  }
}