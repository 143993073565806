.dashboard-content{
  background-color: #e0e0e0;
  min-height: 100vh;
  h2{
    margin: 0;
  }
}

.dashboard-grid{
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.dashboard-item{
  padding: 20px;
  background: white;
  margin: 20px;
  border-radius: 10px;
}