.image-gallery-content{
    display: flex;
    flex-wrap: wrap;
    width: 600px;

    .image-gallery-item-container{
        margin: 2px;
        padding: 5px;
        background-color: darkred;
        display: flex;
        cursor: pointer;
        position: relative;

        .image-cover{
            position: absolute;
            width: 150px;
            height: 150px;
        }
    }

    .image-gallery-item-container.selected{
        background-color: rgb(17, 223, 62);
        
        .image-cover{
            background-color: rgba(17, 223, 27, 0.226);
        }
    }

    .image-gallery-item-container:hover{
        background-color: rgb(219, 223, 17);
        
        .image-cover{
            background-color: rgba(220, 223, 17, 0.226);
        }
    }

    .image-gallery-item-container.selected:hover{
        background-color: rgb(11, 155, 42);
        
        .image-cover{
            background-color: rgba(7, 110, 12, 0.226);
        }
    }
    
    img{
        width: 150px;
        height: 150px;
    }

}

.image-gallery-controls{
    width: 100%;

    .btn {
        width: 50%;
    }
}

.image-gallery-upload-wrapper{
    width: 100%;
    .uploader {
        width: 100%;
    }

    .image-gallery-upload{
        cursor: pointer;
        background-color: rgb(230, 230, 230);
        padding: 20px;
        margin: 6px;
        border: 1px gray;
        border-style: dashed;
        border-radius: 10px;
        width: 100%;
    }

    .image-gallery-upload:hover{
        background-color: rgb(243, 243, 243);
        border-color: rgb(170, 170, 170);
    }
}