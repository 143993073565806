.voucher-dashboard-component-content {

  min-height: 120px;

  .voucher-dashboard-success{
    color: green;
  }
  .voucher-dashboard-error{
    color: red;
  }

  .voucher-dashboard-container{
    display: flex;
  }
  .voucher-dashboard-item{
    width: 250px;
    margin: 5px;
  }
}