.eshop-dashboard-component-content{
  .eshop-dashboard-component-item{
    padding: 5px;
    background-color: #dceffc;
    margin: 5px;
  }

  .eshop-container{
    .eshop-container-item{
      margin-left: 3px;
      margin-right: 3px;
    }
  }

  .eshop-shop-list{
    margin-top: 5px;
  }
  .eshop-shop-list-item{
    margin-top: 10px;
  }
}
