@import "../variables";

.side-layout{
  display: inline-flex;
  width: 100%;
}

#root{
  width: 100%;
}

.side-nav-spacer{
  min-height: 100vh;
}

.side-layout-content {
  margin-left: $side-nav-width;;
  width: 100%;

  &.collapsed {
    margin-left: 40px;
  }
}

.layout-content{
  margin-top: 100px;
}