.content-gallery-content{
    display: flex;
    flex-wrap: wrap;

    .content-gallery-item-container{
        margin: 2px;
        padding: 5px;
        background-color: darkred;
        display: flex;
        cursor: pointer;
        position: relative;
        width: 150px;
        height: 200px;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;

        .image-cover{
            position: absolute;
            width: 150px;
            height: 150px;
        }
    }

    .content-gallery-item-container.selected{
        background-color: rgb(17, 223, 62);

        img{
            filter: grayscale(60%);
        }
    }

    .content-gallery-item-container:hover{
        background-color: rgb(219, 223, 17);

    }

    .content-gallery-item-container.selected:hover{
        background-color: rgb(11, 155, 42);

    }
    
    img{
        max-width: 150px;
        max-height: 150px;
    }

}

.content-gallery-controls{
    width: 100%;

    .btn {
        width: 50%;
    }
}

.content-gallery-upload-wrapper{
    width: 100%;
    .uploader {
        width: 100%;
    }

    .content-gallery-upload{
        cursor: pointer;
        background-color: rgb(230, 230, 230);
        padding: 20px;
        margin: 6px;
        border: 1px gray;
        border-style: dashed;
        border-radius: 10px;
        width: 100%;
    }

    .content-gallery-upload:hover{
        background-color: rgb(243, 243, 243);
        border-color: rgb(170, 170, 170);
    }
}