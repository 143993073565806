$nav-bg-color: #333;
$nav-submenu-bg-color: #fff;
$nav-text-color: #d43a3a;

$side-nav-text-color: #3ad4c7;

$yellow-color: #F7CA18;

// Colors

// Font sizes

// Sizes
$nav-height: 60px;
$side-nav-width: 150px;