

.login-view{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-container{
  .login-header{
    font-weight: bold;
    font-size: 1.5em;
  }
  button {
    border: none;
    background: darkred;
    padding: 10px;
    color: white;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1.2em;
    width: 100%;
    transition: 0.2s;
  }

  button:hover{
    background: #b43737;
  }

  .login-error{
    color:red;
    height: 30px;
    line-height: 30px;
    font-weight: bold;

  }
}
