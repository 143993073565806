.order-transaction-field-container{
    background-color: #c7c7c7;
    width: 500px;
    min-height: 150px;
    max-height: 300px;
    overflow: auto;
    padding: 10px;
    position: relative;
    border-radius: 10px;
}

.order-transaction-field-item{
    display: flex;
    background: white;
    padding: 10px;
    border-radius: 10px;
    margin-top: 10px
}

.order-transaction-field-item-content{
    width: 80%;
}

.order-transaction-field-item-controls{
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.order-transaction-field-container-controls{
    display: flex;
    justify-content: right;

    button{
        margin: 5px;
    }
}