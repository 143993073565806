@import "../../variables";

nav.nav-menu{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $nav-height;
  background-color: $nav-bg-color;
  color: $nav-text-color;
  display: flex;
  align-items: center;
  z-index: 999;

  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin-right: 20px;
      
      &:last-child {
        margin-right: 0;
      }

      a, span{
        color: $nav-text-color;
        text-decoration: none;
        font-weight: bold;
        transition: all 0.2s ease;

        &:hover {
          color: lighten($nav-text-color, 20%);
        }
      }
    }
  }

  .menu-control-panel{
    position: absolute;
    right: 100px;
  }

  .nav-menu-preview{
    position: absolute;
    top: 10px;
    right: 5px;
    button {
      cursor: pointer;
      color: #6a6ae7;
      border: 3px solid #6d6dcb;
      background: #c6c6fa;
      border-radius: 5px;
    }
  }

}

.nav-menu-header-content{
  margin-right: 10px;
  background: #1d2731;
  padding: 0 10px;
  height: $nav-height;
  position: relative;

  .nav-menu-header-label{
    font-weight: bold;
    color: yellow;
  }

  .nav-menu-header-version{
    color: yellow;
  }
}

.menu {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu li {
  position: relative;
}

.menu a, .menu span{
  display: block;
  padding: 10px;
  cursor: pointer;
}

.menu a:hover, .menu span:hover {
  color: lighten($nav-text-color, 15%);
  background-color: lighten($nav-bg-color, 8%);;
}

.submenu {
  position: absolute;
  top: 100%;
  left: 0;
  display: block !important;
  visibility: hidden;
  min-width: 200px;
  padding: 10px;
  background-color: $nav-bg-color;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  transition: visibility 0s 0.2s, opacity 0.2s linear;
  opacity: 0;

}

.menu li:hover > .submenu {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.2s linear;
}


@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
}
