.advanced-checkbox-container{
  display: flex;
 

  .advanced-checkbox-value{
    cursor: pointer;
  }

  margin-top: 10px;
  margin-bottom: 10px;
}