.header{
    padding: 5px;
}

.modal-image {
    max-width: 250px;
    max-height: 250px;
}

.column-image {
    max-width: 35px;
    max-height: 35px;
}

.ant-modal-content{
    width: 800px;
}


.view-control-panel{
    margin: 10px;
    display: flex;
}

.base-admin-view-table-controls{
    button {
        margin-left: 3px;
    }
}

.base-admin-custom-controls{
    margin-left: 10px;
}

.base-admin-custom-control-item{

}

.base-admin-searchbar{
    position: absolute;
    right: 40px;
}

.base-admin-condition-filter{
    display: flex;
    margin-left: 10px;

    .base-admin-condition-filter-item{
        margin-left: 2px;
        margin-right: 2px;
        button{
            background: None;
            color: darkred;
            border: 1px solid darkred;
        }

        &.active{
            button {
                background-color: orange;
                color: black;
            }
        }
    }
}

.base-admin-controls-refresh{
    margin-right: 3px;
    margin-left: 3px;
}

.admin-loading-container{
    position: absolute;
    justify-content: center;
    display: flex;
    z-index: 90;
    width: 100%;
    height: 100%;
    background: #80808038;
    align-items: center;
}

.content{
    position: relative;
}