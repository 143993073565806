@import 'react-big-calendar/lib/sass/styles';
@import 'react-big-calendar/lib/addons/dragAndDrop/styles';
$big-calendar-border-radius: 8px;

.big-calendar-wrapper{
    position: relative;

    .big-calendar-popup-menu {
        position: absolute;
        padding: 5px;
        background-color: white;
        color: black;
        font-weight: 500;
        min-width: 150px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

        border-radius: $big-calendar-border-radius;

        .big-calendar-popup-menu-header{
            font-size: 0.8em;
            color: rgb(105, 105, 105);
        }

        .big-calendar-popup-menu-item {
            padding: 10px;
            //width: 100%;
            cursor: pointer;
            border-radius: $big-calendar-border-radius;
        }

        .big-calendar-popup-menu-item:hover{
            background-color: lightblue;
        }

    }

    .big-calendar-controls{
        .ant-select{
            width: 150px;
        }
    }
    
    .rbc-event {
        background-color: #990e0e !important;
    }

    .rbc-day-slot .rbc-background-event{
        background-color: #00318bd3 !important;
    }

    .date-in-past {
        width: 14.3%;
        background: #ccc;
        border-right: solid 1px #fff;
    }
    .big-calendar-blank-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;

        .big-calendar-blank {
            font-size: 2em;
            font-weight: bold;

        }
    }
}

.big-calendar-modal{
    .ant-select{
        width: 200px;
    }
}

